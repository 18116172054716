:global(#App) {
  background-color: #eaeaea;
}
:global(#App) .frame {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}
:global(#App) .frame .panels {
  position: relative;
  flex: 1;
  pointer-events: none;
}
:global(#App) .frame .panels .BG {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
:global(#App) .frame .panels > * {
  pointer-events: auto;
}
:global(#App) main {
  position: relative;
  min-height: 100vh;
}
